.metadata-container {
  margin-bottom: 64px;
}

.metadata-container > h1 {
  font-size: 48px;
  margin-top: 16px;
}

.singlepost-metadata,
.singlepost-statistic {
  display: flex;
  align-items: center;
}

.singlepost-metadata {
  justify-content: flex-start;
}

.singlepost-metadata > span:nth-child(2) {
  margin-left: 12px;
}

.singlepost-statistic {
  justify-content: space-evenly;
  margin-bottom: 64px;
}

.singlepost-mainImage {
  margin-bottom: 64px;
}

.singlepost-mainImage > div:first-child {
  width: 100%;
}

.singlepost-blockContent {
  margin: 0 auto;
  max-width: 75%;
}

.singlepost-blockContent ul {
  margin-left: 16px;
}

.singlepost-blockContent blockquote {
  background: #2f3144;
  border-radius: 2px;
  border-left: 2px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

.singlepost-blockContent blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

/* .singlepost-blockContent p,
.singlepost-blockContent ul,
.singlepost-blockContent ol,
.singlepost-blockContent dl {
  font-size: 21px;
  letter-spacing: -0.063px;
  line-height: 32px;
} */

.singlepost-blockContent h1 {
  font-size: 40px;
  margin-top: 80px;
  margin-bottom: 8px;
}

.singlepost-blockContent h2 {
  font-size: 32px;
  margin-top: 48px;
  margin-bottom: 8px;
}

.singlepost-blockContent h3 {
  margin-top: 24px;
  margin-bottom: 8px;
}

figure {
  max-width: 100%;
}

figure img {
  max-width: inherit;
}

@media screen and (max-width: 768px) {
  .singlepost-blockContent {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* .singlepost-blockContent {
    max-width: 100%;
  } */
}
