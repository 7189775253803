@import "~antd/dist/antd.dark.less";

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-family: Poppins, serif;@body-background: #3E4059;@text-color-dark: #F3F1E0;@text-color: #F3F1E0;@primary-color:  #ECCD97;@heading-color: #F3F1E0;@btn-primary-color: #3E4059;@btn-font-weight: 600;@font-size-base: 16px;@btn-border-radius-base: 8px;@card-radius: 16px;@card-background: #2f3144;