.footer-section {
  margin-top: 120px;
  height: 160px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.credits {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  footer {
    height: 96px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .credits {
    justify-content: center;
  }
}
