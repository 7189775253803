header {
  width: 100%;
  padding: 0 16px;
  position: sticky;
  top: 0;
  background-color: rgba(62, 64, 89, 0.8);
  z-index: 100;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  header {
    padding: 0 40px;
  }
}

@media screen and (max-width: 480px) {
  header {
    padding: 0 24px;
  }
}
