h1 {
  font-size: 40px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 32px !important;
  font-weight: bold !important;
}

h3 {
  font-size: 24px !important;
  font-weight: bold !important;
}

.template-width {
  margin: 0 auto;
  max-width: 1200px;
}

section {
  width: 100%;
  padding: 16px;
  margin-top: 40px;
}

/* Breakpoints */

@media screen and (max-width: 1024px) {
  section {
    padding: 40px;
  }
}

@media screen and (max-width: 480px) {
  section {
    padding: 24px;
  }
}

/* antd styles override */

/* .ant-card {
  border-radius: 4px !important;
} */
