.ant-timeline {
  margin: 40px 0;
}

.process-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
}

.steps-container {
  margin: 56px 0;
}
